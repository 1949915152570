[data-grid='open-source'] {
  --col-gap: var(--spacer);
  --fit-max: var(--min-page-column);
  --item-title: var(--h2);

  header {
    --newline: var(--shim);
  }

  .item-title {
    --link: unset;
  }

  [data-logo] {
    height: calc(var(--gutter) * 5);
    width: auto;
    max-width: 75%;
  }
}

.donate-link {
  justify-self: start;

  img {
    inline-size: auto;
    max-block-size: calc(1lh + var(--gutter));
  }
}
