[data-layout='cta'] {
  --media-min: 6em;
  --media-size: calc(4em + 5vw);

  display: grid;
  font-size: var(--small);
  grid-area: var(--cta-grid-area, cta);
  grid-template-columns: minmax(min-content, var(--page));
  justify-content: center;
}
