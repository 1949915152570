[data-callout] {
  --list-padding--default: 1em;
  --inline-bleed: 0;

  background: var(--callout-block-bg, var(--callout));
  border-inline-start: thick solid var(--callout-block-border, var(--border));
  margin-block: var(--callout-margin-block, var(--newline));
  margin-inline: var(--callout-margin-inline, calc(var(--gutter) * -1));
  padding: var(--callout-padding, var(--gutter));

  :is(p, ol, ul):last-child {
    margin-block-end: 0;
  }

  .post-header & {
    --callout-margin-block: var(--double-gutter) 0;
    --callout-margin-inline: auto;
    --callout-padding: var(--gutter) var(--double-gutter);
    --newline: var(--gutter);

    grid-area: note;
  }
}

[data-callout='series'] {
  [aria-current]::marker {
    content: '➡️ ';
    content: '➡️ ' / '';
  }
}
