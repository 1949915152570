@use '../config';

/// # Link Defaults
/// Initial global defaults for links…
/// @group links

// Focus
// -----
/// By default, all elements get a dotted outline on focus.
/// In practice, we can override this when other focus styles exist.
/// @group links
:focus {
  @include config.focus-ring;
}

// Links
// -----
/// Basic link (and link-states) apply action and interaction text colors.
/// Text underlines are also applied,
/// but the target depends on the presence or absence of icons,
/// in order to avoid awkward-looking icon underlines.
/// @group links
a {
  &:link,
  &:visited {
    --underline-color--default: var(--accent);
    --underline-thickness--default: 0.1em;
    --underline-offset--default: 0.15em;

    color: var(--link, var(--action));
  }

  &:hover,
  &:focus {
    --img-bright: 1;
    --img-contrast: 1;
    --underline-color: var(--active);
    --underline-thickness: 0.2em;

    color: var(--link-focus, var(--active));
  }

  // underlines applied to link-text as well as basic link states
  .link-text,
  &:link,
  &:visited {
    @include config.transition('underline');

    text-decoration: underline;
    text-decoration-color: var(
      --underline-color,
      var(--underline-color--default)
    );
    text-decoration-thickness: var(
      --underline-thickness,
      var(--underline-thickness--default)
    );
    text-decoration-skip-ink: auto;
    text-underline-offset: var(
      --underline-offset,
      var(--underline-offset--default)
    );
  }

  // icon links have the underline removed
  // (and applied to the icon-text instead)
  &.icon-link {
    text-decoration: none;
  }
}
